<template>
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Informações financeiras</h1>
      <p class="text-gray-500">
        Insira os dados bancários para recebimento das transações financeiras.
      </p>
    </header>

    <px-radio-selector
      :selectorOptions="selectorOptions"
      class="hidden md:block"
    >
      <template v-slot:content>
        <div v-show="selectorOptions[0].selected" class="mb-4">
          <div label="Chave pix">
            <px-input-select
              label="Informe a chave"
              name="pix"
              placeholder="Chave"
              :items="pixTypes"
              @input="handleInputPix"
            />
          </div>
        </div>
        <div v-show="selectorOptions[1].selected" class="mb-4">
          <div label="Conta bancária" class="space-y-4">
            <px-select
              label="Banco"
              name="numeroBanco"
              placeholder="Selecionar"
              :items="bankList"
              @select="(resetShowError)(numeroBanco = $event)"
            />
            <px-input
              v-model="agencia"
              class="w-1/2"
              name="agencia"
              @pxInput="resetShowError"
            >
              Agência
            </px-input>
            <px-input
              name="contaCorrente"
              placeholder="Somente números"
              v-model="contaCorrente"
              @pxInput="resetShowError"
            >
              Conta corrente com dígito
            </px-input>
          </div>
        </div>
      </template>
    </px-radio-selector>

    <px-accordion-v2
      :selectorOptions="selectorOptions"
      class="block md:hidden"
    >
      <template>
        <div v-show="selectorOptions[0].selected" class="mt-4">
          <div label="Chave pix">
            <px-select
              label="Tipo de chave"
              name="pix"
              placeholder="Selecionar"
              :items="pixTypes"
              class="mb-4"
              @select="(resetShowError)(pix.type = $event)"
            />
            <px-input
              v-model="pix.key"
              name="pixKey"
              placeholder="Digite a chave"
              @pxInput="resetShowError"
            >
              Chave
            </px-input>
          </div>
        </div>
        <div v-show="selectorOptions[1].selected" class="mt-4">
          <div label="Conta bancária">
            <px-select
              label="Banco"
              name="numeroBanco"
              placeholder="Selecionar"
              :items="bankList"
              class="mb-4"
              @select="(resetShowError)(numeroBanco = $event)"
            />
            <px-input
              v-model="agencia"
              class="w-full mb-4"
              name="agencia"
              @pxInput="resetShowError"
            >
              Agência
            </px-input>
            <px-input
              name="contaCorrente"
              placeholder="Somente números"
              v-model="contaCorrente"
              @pxInput="resetShowError"
            >
              Conta corrente com dígito
            </px-input>
          </div>
        </div>
      </template>
    </px-accordion-v2>

    <div class="text-red-700 text-sm mt-2" v-show="showRequiredError">
      Preencha uma das opções
    </div>

    <footer class="flex justify-end space-x-3 mt-6">
      <px-button
        href="/onboarding/endereco-comercial"
        class="w-full md:w-auto"
      >
        <ChevronLeftIcon slot="left" size="20"/>
        <span>Voltar</span>
      </px-button>

      <px-button
        kind="primary"
        class="w-full md:w-auto"
        @click="handleClick"
      >
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
  </template-onboarding>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { ChevronRightIcon, ChevronLeftIcon } from '@vue-hero-icons/solid';
import getBankList from '@/lib/getBankList';
import TemplateOnboarding from './TemplateOnboarding.vue';
import {
  PxAccordionV2,
  PxButton,
  PxInput,
  PxInputSelect,
  PxSelect,
  PxRadioSelector,
} from '../../../components/custom';
import utilValidator from '../../../util/util.validator';

export default {
  name: 'FinancialInformation',
  metaInfo: {
    title: 'Informações financeiras',
  },
  components: {
    TemplateOnboarding,
    PxAccordionV2,
    PxButton,
    PxInput,
    PxInputSelect,
    PxSelect,
    PxRadioSelector,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  data: () => ({
    currentOnboardingStep: 3,
    bankList: [],
    numeroBanco: '',
    agencia: '',
    contaCorrente: '',
    pix: {
      key: '',
      type: '',
    },
    showRequiredError: false,
  }),
  computed: {
    ...mapState('onboarding', ['onboardingSteps', 'pixTypes', 'selectorOptions']),
  },
  methods: {
    ...mapMutations('onboarding', ['setFinanceData', 'setRetailerId']),
    ...mapActions('onboarding', ['saveMiddleStep']),
    handleInputPix({ selectValue, inputValue }) {
      this.showRequiredError = false;
      this.pix = {
        key: inputValue,
        type: selectValue,
      };
    },
    getInvalidInputList() {
      const list = [];
      if (!this.numeroBanco && !this.agencia && !this.contaCorrente && !this.pix.key) {
        list.push('finance');
      } else if (this.pix.type === 'Email' && !utilValidator.isValidEmail(this.pix.key)) {
        list.push('finance');
      }
      return list;
    },
    handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.showRequiredError = true;
      } else {
        const bank = {
          numeroBanco: this.numeroBanco,
          agencia: this.agencia,
          contaCorrente: this.contaCorrente,
        };
        this.setFinanceData({
          bank: this.numeroBanco && this.agencia && this.contaCorrente
            ? bank
            : null,
          pix: this.pix.key && this.pix.type ? this.pix : null,
        });
        this.setRetailerId();
        this.saveMiddleStep();
      }
    },
    resetShowError() {
      this.showRequiredError = false;
    },
  },
  created() {
    this.bankList = getBankList().map((item) => ({
      value: item.value,
      label: `
        <span class="text-gray-500 w-8 mr-2">${item.value}</span>
        <span>${item.label}</span>`,
    }));
  },
};
</script>
